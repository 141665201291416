.industryApplicationContent {
  &>.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 15px;

    .industryApplicationContentItem {
      border-radius: 10px;
      overflow: hidden;

      &>.text {
        padding: 1%;
        padding-bottom: calc(1% + 15px);
        margin-top: -5px;
        border: 1px solid #ECEBEB;
        border-top: 0;
        border-radius: 0 0 10px 10px;

        &>.title {
          font-size: 24px;
          color: #5B5B5B;
          letter-spacing: 0.2px;
          margin-bottom: 10px;
          position: relative;
          font-weight: bold;
          line-height: 50px;
          border-bottom: 1px solid #DED8D8;
          position: relative;

          &::after {
            content: '';
            width: 5%;
            height: 2px;
            background-color: #458BF1;
            transition: width 1s;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        &>.describe {
          font-size: 14px;
          color: #6F6E6E;
          letter-spacing: 0.12px;
          line-height: 23px;
          font-family: "SourceHanSansCN-Regular";
        }
      }

      &:hover {
        background-color: transparent;
        box-shadow: 2px 8px 12px #b8b8b8;
        
        .title::after {
          width: 100%;
        }
      }
    }
  }

  &>.pageSet {
    width: 100%;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .pagination_number {
      color: #9B9B9B;
      font-size: 12px;
      margin-right: 10px;
      font-family: FZLTZHK--GBK1-0;
    }

    .pagination_button {
      cursor: pointer;
      color: #9B9B9B;
      width: 22px;
      height: 22px;
      background-color: white;
      border: 1px solid #9B9B9B;
      border-radius: 2px;

      &:hover {
        color: #458BF1;
        border-color: #458BF1;
      }
    }

    .pageSelected {
      border: 0;
      color: #FFF !important;
      background-color: #458BF1;
    }
  }
}