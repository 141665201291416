.footer {
  width: 100%;
  color: #fff;
  padding: 30px 20%;
  background-color: #323232;

  &>.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>.footerLeft {
      width: 60%;
  
      &>.logoWrap {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        &>img {
          width: 167px;
        }
        &>.text {
          width: 66%;
          font-size: 24px;
          display: flex;
          gap: 9%;
        }
      }
      
      .menuList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        padding-right: 50px;
  
        dl {
          margin: 0;

          dt, dd {
            height: 14px;
            cursor: pointer;
            letter-spacing: 0.12px;
            font-family: "SourceHanSansCN-Regular";
    
            &:hover {
              color: #fff;
              font-weight: 500;
            }
            &:first-child {
              padding-left: 0;
            }
          }

          dt {
            padding: 20px 0;
            color: #fff;
            font-size: 14px;

            &:hover {
              font-size: 15px;
            }
          }
          dd {
            margin: 0;
            padding: 10px 0;
            font-size: 12px;
            color: rgba($color: #FDFEFF, $alpha: .6);

            &:hover {
              font-size: 13px;
            }
          }
        }

      }
    }
    &>.footerRight {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>.imgWrap {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-direction: column;

        &>img {
          width: 100px;
        }
      }
  
      &>.text {
        font-family: SourceHanSansCN-Medium;
        
        &>.title {
          padding: 20px 0;
          font-size: 20px;
        }
        &>.describe {
          font-size: 12px;
          line-height: 24px;
          color: rgba($color: #FDFEFF, $alpha: .6);
        }
      }
    }
  }
  
  .copyright {
    font-size: 12px;
    color: #C9CACB;
    letter-spacing: 0.1px;
    font-family: "SourceHanSansCN-Regular";
    display: flex;
    justify-content: center;
    gap: 30px;
  }

}