.sideNav {
  width: 100px;
  height: 360px;
  padding: 40px 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 60%;
  right: 0;

  &::after {
    content: '';
    height: 100%;
    border-right: 1px dashed #babbbb;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
  }

  .sideNavItem {
    cursor: pointer;

    .circle {
      width: 26px;
      height: 26px;
      margin: auto;
      padding: 4px;
      border-radius: 50%;
      border: 1px solid #babbbb;

      &>div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #babbbb;
      }
    }
    .text {
      color: #babbbb;
      text-align: center;
    }
  }

  .sideNavItemSelected {
    transition: all 1s;
    transform: scale(1.05);

    .circle {
      border-color: #308dff;
      &>div {
        background-color: #308dff;
      }
    }
    .text {
      color: #308dff;
    }
  }
}