.App {
  margin: auto;
  max-width: 1920px;
  min-width: 1600px;

  div {
    box-sizing: border-box;
  }

  h1 {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    padding: 0 20%;

    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
  
      &>.el-tabs__nav {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .el-tabs__item {
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          color: #5B5B5B;
          font-weight: bold;
          text-align: center;
          font-family: SourceHanSansCN-Bold;
    
          &:hover {
            color: #458BF1;
          }
        }
        .is-active {
          color: #458BF1;
          position: relative;

          &::after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: #458BF1;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .el-tabs__active-bar {
          display: none;
        }
      }
    }
  }

  .wrap {
    padding: 20px 20%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &>.title {
      font-size: 36px;
      font-weight: bold;
      color: #313131;
      position: relative;
      padding-bottom: 10px;
      letter-spacing: 0.3px;

      &::after {
        content: '';
        width: 80px;
        height: 2px;
        background-color: #308DFF;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    
    &>.content {
      padding: 30px 0;
    }
  }

  .ant-float-btn-group {
    width: 50px !important;

    .ant-float-btn {
      width: 100%;

      .ant-float-btn-body {
        width: 42px !important;
        height: 42px !important;
        .ant-float-btn-description {
          font-size: 14px !important;
        }
      }
    }
  }
}
