.technology {
  .wrap {
    padding: 0 !important;

    &>.content {
      width: 100%;
      padding: 50px 20% !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>img {
        width: 48%;
      }

      &>.text {
        &>.title {
          font-size: 24px;
          padding: 20px 0;
          color: #5B5B5B;
          letter-spacing: 0.2px;
          font-family: SourceHanSansCN-Bold;
        }
        &>.describe {
          font-size: 14px;
          color: #4A4A4A;
          line-height: 24px;
          letter-spacing: 0.12px;
          font-family: "SourceHanSansCN-Regular";

          &>.title {
            font-size: 16px;
            padding: 10px 0;
          }
        }
      }
    }
  }
}