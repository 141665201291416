.contact {
  .wrap {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .company {
    height: 550px;

    &>.content {
      display: flex;
      justify-content: space-between;

      &>img {
        width: 50%;
      }

      &>.text {
        text-align: left;
        font-size: 14px;
        color: #888787;
        line-height: 24px;
        letter-spacing: 0.12px;
        font-family: "SourceHanSansCN-Regular";

        &>.title {
          height: 16px;
          font-size: 16px;
          color: #6A6969;
          margin-top: 10px;
          font-weight: bold;
          letter-spacing: 0.13px;
          font-family: SourceHanSansCN-Bold;
        }
      }
    }
  }

  .course {
    height: 660px;
    padding: 50px 100px !important;
    background-color: #FAFCFE;
    position: relative;

    .arrow {
      border: 0;
      width: 63px;
      height: 63px;
      cursor: pointer;
      color: #B5BDC1;
      font-size: 20px;
      border-radius: 50%;
      background-color: #eaedee;
      position: absolute;

      &:hover {
        color: #308dff;
        background-color: rgba($color: #eaedee, $alpha: .8);
      }
    }
    .arrowLeft {
      top: 50%;
      left: 30px;
    }
    .arrowRight {
      top: 50%;
      right: 30px;
    }

    &>.listWrap {
      width: 100%;
      height: 100%;
      overflow: hidden;

      &>.list {
        height: 100%;
        transition: all 2s;
        display: flex;
        align-items: center;
        gap: 34px;
  
        &>.listItem {
          width: 200px;
          min-width: 200px;
          height: 48px;
          font-size: 30px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #c5efff;
          position: relative;

          &>.circle {
            width: 32px;
            height: 32px;
            padding: 6px;
            border-radius: 50%;
            border: 1px solid #24c2ff;
            background-color: #c5efff;
            position: absolute;

            &>div {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #24c2ff;
            }
          }
          &>.event {
            width: 260px;
            height: 80px;
            font-size: 14px;
            color: #666666;
            padding: 10px 5px;
            border: 1px solid #24c2ff;
            background-color: #c5efff;
            position: absolute;

            &::after {
              content: '';
              border-bottom: 10px solid #24c2ff;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              position: absolute;
            }
          }

          &:nth-of-type(odd) {
            &>.circle {
              top: -50px;
            }
            &>.event {
              top: -160px;

              &::after {
                left: 50%;
                bottom: -10px;
                transform: translateX(-50%) rotate(180deg);
              }
            }
          }
          &:nth-of-type(even) {
            &>.circle {
              bottom: -50px;
            }
            &>.event {
              bottom: -160px;

              &::after {
                left: 50%;
                top: -10px;
                transform: translateX(-50%);
              }
            }
          }
  
          &::before {
            content: '';
            width: 25px;
            height: 48px;
            background-color: #c5efff;
            clip-path: polygon(0 0, 100% 50%, 0 100%);
            position: absolute;
            right: -25px;
          }
          &:not(:first-child) {
            &::after {
              content: '';
              width: 23px;
              height: 48px;
              background-color: #c5efff;
              clip-path: polygon(0 0, 100% 50%, 0 100%, 100% 100%, 100% 0);
              position: absolute;
              top: 0;
              left: -23px;
            }
          }

          &.active, &:hover {
            color: #ffffff;
            background-color: #24C2FF;
            &::before {
              background-color: #24C2FF;
            }
            &::after {
              background-color: #24C2FF;
            }
            &>.circle {
              border-color: #24C2FF;
              background-color: #24C2FF66;
              &>div {
                background-color: #24C2FF;
              }
            }
            &>.event {
              border: 0;
              color: #fff;
              background-color: #24C2FF;

              &::after {
                border-bottom: 10px solid #24C2FF;
              }
            }
          }
          &:hover ~ .active {
            color: #000;
            background-color: #c5efff;
            &::before {
              background-color: #c5efff;
            }
            &::after {
              background-color: #c5efff;
            }
            &>.circle {
              background-color: #c5efff;
              border-bottom: 1px solid #24c2ff;
              &>div {
                background-color: #24c2ff;
              }
            }
            &>.event {
              border: 0;
              color: #000;
              background-color: #c5efff;
              border: 1px solid #24c2ff;

              &::after {
                border-bottom: 10px solid #24c2ff;
              }
            }
          }
        }
      }
    }
  }

  .contactUs {
    background-color: #FAFCFE;
    &>.content {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &>img, &>.map {
        width: 50%;
      }

      &>.text {
        &>.title {
          display: block;
          width: 399px;
          font-size: 24px;
          color: #606060;
          letter-spacing: 0.2px;
          padding-bottom: 19.5px;
          margin-bottom: 29.5px;
          font-weight: bold;
          border-bottom: 1px solid #AEB3B9;
          font-family: SourceHanSansCN-Bold;
        }
        &>.describe {
          text-align: left;
          font-size: 14px;
          color: #888787;
          line-height: 24px;
          letter-spacing: 0.12px;
          font-family: "SourceHanSansCN-Regular";
        }
      }
    }
  }

  .messageWrap {
    width: 100%;
    padding: 50px 20%;
    background-color: #FAFCFE;
    display: flex;
    gap: 20px;

    input {
      font-size: 12px;
      border: none;
      outline: none;
      line-height: 12px;
      letter-spacing: 0.1px;
      font-family: SourceHanSansCN-Bold;
    }
    
    textarea {
      border: none;
      height: 100px;
      max-height: 100px;
    }

    .left {
      flex: 1;
      display: flex;
      gap: 10px;
      flex-direction: column;

      &>.verification {
        user-select: none;
        position: relative;

        &>.code {
          width: 80px;
          height: 22px;
          cursor: pointer;
          font-weight: bold;
          color: #498df1;
          text-align: center;
          background-color: #e9e6e6;
          position: absolute;
          top: 50%;
          right: 2%;
          transform: translateY(-50%);
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: flex-end;

      button {
        width: 80px;
      }
    }
  }
}