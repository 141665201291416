.home {

  &>.wrap {
    padding: 0 20%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &>.title {
      font-size: 36px;
      color: #313131;
      position: relative;
      padding-bottom: 10px;
      letter-spacing: 0.3px;

      &::after {
        content: '';
        width: 80px;
        height: 2px;
        background-color: #308DFF;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    
    &>.describe {
      font-size: 14px;
      color: #6A6969;
      letter-spacing: 0.12px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .productCenter {
    padding-top: 50px;

    &>.content {
      width: 100%;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 50px 0;

      .productCenterItem {
        height: 460px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.5s;
        background-size: 100% 100%;
        position: relative;

        &>.content {
          width: 100%;
          height: 100%;
          padding: 30px 0;
          display: flex;
          gap: 20px;
          align-items: center;
          flex-direction: column;
          background-color: rgba(0,0,0,0.4);

          &>.title {
            font-size: 24px;
            color: #FFFFFF;
            display: block;
            padding-top: 50px;
            letter-spacing: 0.2px;
          }
          &>.describe {
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.12px;
          }

          &>button {
            width: 88px;
            height: 35px;
            cursor: pointer;
            font-size: 14px;
            color: #fff;
            border-radius: 5px;
            letter-spacing: 0.12px;
            border: 1px solid #3681EF;
            background-color: rgba($color: #3681EF, $alpha: .3);
            transition: all 0.5s;
          }
        }

        &:hover {
          transform: scale(1.05);
          font-weight: bold;

          button {
            background-color: #308DFF;
          }
        }
      }
    }
  }

  .coreTechnology {
    height: 650px;
    width: 100%;
    position: relative;

    &>img {
      width: 100%;
      height: 580px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &>.title, &>.describe {
      color: #fff !important;
    }
    &>.title {
      padding-top: 50px !important;

      &::after {
        background-color: #98C5FB !important;
      }
    }

    &>.content {
      width: 100%;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      background-color: #ffffff;
      padding: 50px 0;
      border-radius: 5px;
      margin-top: 40px;
      box-shadow: 0 10px 20px 12px rgba(159, 176, 186, .14);

      .coreTechnologyItem {
        display: flex;
        align-items: center;
        flex-direction: column;

        &:not(:last-child) {
          border-right: 2px solid #EEF6FF;;
        }

        &>.title {
          font-size: 23px;
          color: #5B5B5B;
          margin-top: 17px;
          letter-spacing: -0.06px;
        }
        & li {
          font-size: 14px;
          color: #6A6969;
          letter-spacing: 0.12px;

          &::marker {
            color: cyan;
          }
        }
        &>button {
          width: 120px;
          height: 35px;
          cursor: pointer;
          font-size: 14px;
          color: #308DFF;
          border-radius: 96px;
          letter-spacing: 0.12px;
          border: 1px solid #3681EF;
          background-color: transparent;
          transition: all 0.5s;

          &:hover {
            color: #fff;
            background-color: #308DFF;
          }
        }
      }
    }
  }

  .functionCore {
    width: 100%;
    height: 700px;
    padding-top: 50px !important;

    &>.functionCoreBottomTitle {
      color: #308DFF;
      font-size: 16px;
      line-height: 24px;
    }
    &>.functionCoreBottomText {
      color: #6F6E6E;
      font-size: 14px;
      line-height: 24px;
    }

    &>.el-carousel {
      width: 100%;
      height: 100%;
      margin-top: 50px;

      .el-carousel__container {
        height: 100%;

        .el-carousel__item {
          display: flex;
          align-items: center;
          justify-content: center;
  
          img {
            width: 500px;
            transition: width 0.5;
          }
        }
        .is-active {
          img {
            width: 800px !important;
            height: 500px !important;
          }
        }
      }
      .el-carousel__indicators {
        display: none;
      }
    }
  }

  .industryApplication {
    padding: 0 !important;
    padding-top: 50px !important;

    &>.content {
      width: 100%;
      height: 520px;
      display: flex;
      padding-top: 50px;
      padding-bottom: 0 !important;

      &>.industryApplicationItem {
        flex: 1;
        cursor: pointer;
        padding: 80px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background-size: 101% 100% !important;

        &>.text {
          display: flex;
          align-items: center;
          flex-direction: column;

          &>.title {
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: 0.2px;
          }
          &>.describe {
            padding-top: 21px;
            margin: 0 auto;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0.12px;
            text-align: center;
            line-height: 24px;
          }
        }
        
        &:hover {
          & .title, & .describe {
            color: #308DFF;
          }
        }
      }
    }
  }
}