.ProductCenterOther {

  &>.introduced {
    &>.content {
      width: 100%;
      display: flex;
      gap: 20px;

      &>.introducedItem {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #E8EDF9;

        &>.title {
          width: 100%;
          text-align: center;
          height: 50px;
          font-size: 20px;
          color: #308DFF;
          line-height: 50px;
          background: #F4F8FF;
          letter-spacing: -0.06px;
          border-bottom: 1px solid #E8EDF9;
          font-family: "SourceHanSansCN-Regular";
        }
        &>.content {
          padding: 20px;
          padding-bottom: 60px;
          display: flex;
          align-items: center;
          flex-direction: column;

          &>.text {
            text-align: justify;
            padding-top: 10px;
            font-size: 14px;
            color: #4A4A4A;
            line-height: 24px;
            letter-spacing: 0.12px;
            font-family: "SourceHanSansCN-Regular";
          }
        }
      }
    }
  }

  &>.brightSpot {
    background-color: #FAFCFE;

    &>.content {
      width: 100%;
      display: flex;
      gap: 100px;

      &>.brightSpotItem {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;

        &>.title {
          font-size: 20px;
          color: #308DFF;
          margin-top: 18px;
          text-align: center;
          letter-spacing: -0.06px;
          font-family: "SourceHanSansCN-Regular";
        }
        &>.content {
          text-align: justify;
          font-size: 14px;
          color: #4A4A4A;
          letter-spacing: 0.12px;
          line-height: 24px;
          margin-top: 20px;
          font-family: "SourceHanSansCN-Regular";
        }
      }
    }
  }

  &>.architecture {
    &>.content {
      width: 100%;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
      }
    }
  }

  &>.show {
    background-color: #FAFCFE;

    &>.content {
      width: 100%;
      display: flex;
      gap: 30px;
      padding: 50px 0;

      &>.text {
        flex: 1.5;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>.title {
          font-size: 17px;
          color: #4A4A4A;
          letter-spacing: 0.9px;
          font-weight: bold;
          text-align: left;
          margin-top: 5px;
        }
        &>.describe {
          font-size: 14px;
          color: #4A4A4A;
          letter-spacing: 0.9px;
          margin-top: 8px;
          text-align: left;
          line-height: 27px;
        }
      }

      img {
        flex: 1;
      }
    }
  }

  &>.government {
    &>.content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      padding: 50px 0;

      &>.text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>.title {
          font-size: 17px;
          color: #4A4A4A;
          letter-spacing: 0.9px;
          font-weight: bold;
          text-align: left;
          margin-top: 5px;
        }
        &>.describe {
          font-size: 14px;
          color: #4A4A4A;
          letter-spacing: 0.9px;
          margin-top: 8px;
          text-align: left;
          line-height: 27px;
        }
      }

      img {
        flex: 1;
      }
    }
  }

  .showText {
    margin: auto;
    width: 570px;
    text-align: center;
    white-space: pre-wrap;
    padding: 0 10px;

    &.title {
      padding-top: 20px;
      color: #458BF1;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.text {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}